import axios from 'axios'
import { getToken } from "../utils/setToken.js";

export const downloadExcel = (params,url) => {
    return axios({
        method: 'POST',
        headers: {
            'Content-Type': 'application/vnd.ms-excel;charset=utf-8',
            token: getToken('token')
        },
        responseType: 'blob',
        url: process.env.VUE_APP_URL + url,
        params
    })
}

